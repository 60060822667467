<template>
  <div class="back" @click="back()">
    <img
      src="https://oss.culturalcloud.net/furong/202210/302124275v99.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    pre: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    prePage: {
      type: String,
      default: () => {
        return "";
      },
    },
    disable: {
      //是否禁用默认回退
      type: Boolean,
      return: false,
    },
  },
  methods: {
    back() {
      if (this.disable) {
        this.$emit("back");
        return;
      }
      if (this.pre) {
        this.$router.go(-1);
      } else {
        if (this.prePage) {
          this.$router.push(this.prePage);
        } else {
          this.$router.push("/");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  width: 80px;
  height: 80px;
  position: absolute;
  cursor: pointer;
  left: 50px;
  top: 50px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 9;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
