<template>
	<div v-if="!edit" @click="input">{{value}}</div>
	<div v-else>
		<el-input v-if="type=='textarea'" class="input" ref="input" type="textarea" autosize @blur="update"
			placeholder="请输入内容" v-model="content">
		</el-input>
		<el-input v-if="type=='text'" class="input" ref="input" @blur="update" v-model="content" placeholder="请输入内容">
		</el-input>
		<el-input v-if="type=='number'" :maxlength="8" :max="8" class="input" type="number" ref="input" @blur="update" v-model="content" placeholder="请输入内容">
		</el-input>
		<el-date-picker style="width: 100%;" v-if="type=='date'" ref="input" v-model="content" @blur="update" value-format="yyyy-MM-dd"
			align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions">
		</el-date-picker>
	</div>
</template>

<script>
	var vm = this
	export default {
		props: {
			value: {
				type: [String]
			},
			isDisable:{
				type:Boolean,
				default:false
			},
			type: {
				type: String,
				default: () => {
					return 'text'
				}
			}
		},
		data() {
			return {
				edit: false,
				content: '',
				pickerOptions: {
					disabledDate:(time) =>{ //时间限制
						// console.log(this.isDisable);
						if(this.isDisable) {
							return time.getMonth() > new Date().getMonth() || time.getMonth() < new Date().getMonth();

						}else {
							return false
						}
					}
				}
			}
		},
		methods: {
			input() {
				this.edit = true
				this.$nextTick(() => {
					this.$refs.input.focus()
				})
			},
			update() {

				// console.log();
				this.edit = false
				// 未修改不触发事件
				if (this.value == this.content) return
				// if()
				this.$emit('input', this.content)
				this.$emit('change', this.content)
			}
		},
		created() {
			this.content = this.value
		},
		watch: {
			value: function(newVal, oldVal) {
				if (newVal && newVal != undefined) {
					this.content = newVal
				} else {
					this.content = ''
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.input {
		font-size: 18px;
	}
</style>
