<template>
	<div class="container">
		<back />
		<div class="header">
			<div style="margin-right: auto; margin-left: 150px">
				<el-button-group>
					<el-button type="primary" @click="changePage('/step/four')" icon="el-icon-arrow-left">上一步
					</el-button>
					<el-button type="primary" disabled>下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
				</el-button-group>
			</div>
			<el-select v-model="value" placeholder="请选择" @change="changeMon">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>

		<div class="stepOne">
			<div class="box-left">
				<h3>
					第五步：制定下月计划
				</h3>
				<div class="img">
					<img src="https://oss.culturalcloud.net/furong/202211/05224222s4k8.png" alt="" />
				</div>
				<span style="font-size: 22px; text-align: center">下月经营计划=领导任务+自我工作计划 <br />每人的下月经营计划需由主管部长审核后落定。</span>
				<userMenu :userId="$route.params.useId" :nickName="$route.params.nickName"></userMenu>
			</div>
			<div class="box-right">
				<div class="btn">
					<el-button type="primary" size="mini" @click="openDialog()" icon="el-icon-plus">添加任务</el-button>
				</div>
				<el-table stripe :data="planList" border style="width: 100%;min-height: 600px;"
					:header-cell-style="{ background: '#409EFF', color: '#fff' }" max-height="600">
					<el-table-column label="工作计划">
						<template slot-scope="scope">
							<table-edit type="textarea" @change="edit(scope.row)" class="col"
								v-model="scope.row.planContent"></table-edit>
						</template>
					</el-table-column>
					<el-table-column width="300" label="备注">
						<template slot-scope="scope">
							<table-edit type="textarea" @change="edit(scope.row)" class="col"
								v-model="scope.row.remark"></table-edit>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="danger" size="small" @click="handleDelete(scope.row.planId)"
								icon="el-icon-delete"></el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- end -->


		<!-- 对话框 -->
		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="500px" append-to-body>
			<el-form :model="form" ref="form" :rules="rules" label-width="80px">
				<el-form-item label="计划任务" prop="planContent">
					<el-input :autosize="{ minRows: 3, maxRows: 15 }" type="textarea" v-model="form.planContent"
						placeholder="请输入任务内容"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input :autosize="{ minRows: 3, maxRows: 15 }" type="textarea" v-model="form.remark"
						placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="resetForm('form')">重置</el-button>
				<el-button type="primary" @click="submitForm">确认提交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getPlanList,
		editPlan,
		addPlan,
		delPlan
	} from "@/api";
	import back from "@/components/back";
	import tableEdit from "@/components/table-edit";
	export default {
		name: "five",
		components: {
			back,
			tableEdit
		},
		data() {
			return {
				checked: false,
				rules: {
					planContent: [{
						required: true,
						message: "任务内容不能为空",
						trigger: "blur"
					}],
				},
				title: "",
				open: false,
				form: {},
				planList: [],
				options: [],
				value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1),

			};
		},
		methods: {
			//切换页面
			changePage(path) {
				this.$router.push(path + '/' + this.$route.params.userId + '/' + this.$route.params.nickName)
			},
			edit(e) {
				// console.log(e)
				editPlan(e).then((res) => {
					this.$message({
						message: "修改成功!",
						type: "success",
					});
					this.getPlanData();
				});
			},
			// 提交表单
			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.planId != null) {
							// 修改
							// console.log(this.form.taskId);
							editPlan(this.form).then((res) => {
								this.$message({
									message: "修改计划成功!",
									type: "success",
								});
								this.open = false;
								this.reset();
								this.getPlanData();
							});
						} else {
							// 新增
							addPlan(this.form).then((res) => {
								this.$message({
									message: "新增计划成功!",
									type: "success",
								});
								this.open = false;
								this.reset();
								this.getPlanData();
							});
						}
					}
				});
			},
			// 删除工作
			handleDelete(id) {
				this.$confirm("是否确认删除计划任务的数据项？", "警告", {
					confirmButtonText: "确认",
					cancelButtonText: "取消",
					type: "warning",
				}).then((res) => {
					delPlan(id).then(() => {
						this.getPlanData();
						this.$message({
							message: "删除计划成功!",
							type: "success",
						});
					});
				});
			},
			// 添加打开
			openDialog(item) {
				this.form = {};
				this.open = true;
				this.form.userId = this.$route.params.userId;
				this.form.planType = item.type;
				this.title = "添加计划任务";
			},
			// 更新打开
			handleUpdate(data) {
				this.form = JSON.parse(JSON.stringify(data));
				this.open = true;
				this.title = "修改计划";
			},
			// 重置表单
			reset() {
				this.form = {
					taskId: null,
					userId: null,
					taskContent: null,
					type: null,
					remark: null,
				};
				this.resetForm("form");
			},
			// 取消
			cancel() {
				this.open = false;
				this.reset();
			},
			// 重置表单
			resetForm(formName) {
				let planId = this.form.planId
				this.form = {
					userId: this.$route.params.userId,
					planId: planId
				};
				this.$refs[formName].resetFields();
			},
			getPlanData() {
				getPlanList({
					time: this.value,
					userId: this.$route.params.userId
				}).then((res) => {
					if (res.code === 200) {
						this.planList = res.data
						this.options = res.month.map((item) => {
							return {
								value: item,
								label: item
							}
						})

					}
				});
			},
			changeMon(e) {
				this.getPlanData();
			},
		},
		created() {
			this.getPlanData();
		},
	};
</script>
<style>
	.iconfont {
		font-size: 24px;
	}
</style>
<style lang="scss" scoped>
	::v-deep .el-table .cell {
		line-height: normal;
		// white-space: pre-wrap;

	}

	.target {
		width: 100%;

		.col {
			font-size: 30px;
		}
	}

	.col {
		font-family: monospace;
		white-space: pre-wrap;
		width: 100%;
		min-height: 30px;
		line-height: 30px;
		// padding: 0 10px;
		// text-align: left;
		// display: flex;
		// align-items: center;
		// justify-content: flex-start;
		// border: 2px solid #a7a7a7;
		// box-sizing: border-box;
		// border-top: none;
		font-size: 18px;
		// font-family: monospace;
		// white-space: nowrap;
		// text-overflow: ellipsis;
		// overflow: hidden;

	}

	.container {
		background-color: #fffcfa;
		height: 100vh;
		padding: 30px;

		.header {
			// width: 100%;
			margin-top: 50px;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.step {
				width: 100%;

				display: flex;
				justify-content: space-around;
				text-align: center;
				vertical-align: middle;
				align-items: center;

				color: #fff;

				span {
					border-radius: 5px;
					line-height: 40px;
					background-color: #b99f89;
					display: block;
					width: 17%;
				}
			}
		}

		.stepOne {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 900px;

			.box-left {
				// border: 1px solid #000;
				width: 30%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h3 {
					font-size: 30px;
					width: 100%;
					text-align: center;
					line-height: 80px;
					background-color: #faf5f0;
					margin-bottom: 30px;
					font-weight: bold;
					color: #a46e39;
					border: 3px #a46e39 dashed;
					border-radius: 10px;
				}

				.img {
					width: 100%;

					img {
						width: 100%;
					}
				}
			}

			.box-right {
				width: 65%;

				// display: flex;

				// justify-content: space-between;
				// flex-wrap: wrap;

				.btn {
					margin: 20px 0;
					text-align: right;
				}

				.panel-box {
					border-radius: 5px;
					box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
					// border: 1px solid #000;
					width: 49%;
					height: 380px;
					margin-bottom: 20px;
					overflow: auto;

					.panel-header {
						font-weight: bold;
						font-size: 22px;
						padding: 0 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: sticky;
						top: 0;
						// background-color: #C83434;
						color: #fff;
						line-height: 50px;
						text-align: center;
						z-index: 999;

						span {
							display: flex;
							justify-content: center;
							align-items: center;

							i {
								font-size: 27px;
								color: rgba(0, 0, 0, .5);
							}
						}


					}

					.panel-container {
						height: calc(100% - 50px);
						overflow: hidden;
						background-color: #f5f6f7;

						.panel-content {
							background-color: rgb(227, 227, 227, .36);
							border-bottom: 1px solid #ccc;
							padding: 10px 20px;

							font-size: 20px;
							display: flex;
							justify-content: space-between;
							align-items: center;

							.title {
								// max-width: 350px;
								margin-right: auto;
								// margin-left: 20px;
								// word-break: break-all;
								// text-overflow: ellipsis;
								// display: -webkit-box;
								// -webkit-box-orient: vertical;
								// -webkit-line-clamp: 2;
								// /* 这里是超出几行省略 */
								// overflow: hidden;
							}

							.active {
								text-decoration: line-through;
								color: #ccc;
							}
						}
					}

					.bg {
						background-color: aliceblue;
					}
				}
			}
		}
	}
</style>
